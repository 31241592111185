import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyDPgrRYVSlJXZ9udTnI8PCy-qIGaOIgCVQ",
   authDomain: "spaety-4hd-beefreund.firebaseapp.com",
   databaseURL: "https://spaety-4hd-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
   projectId: "spaety-4hd-beefreund",
   storageBucket: "spaety-4hd-beefreund.appspot.com",
   messagingSenderId: "203572716862",
   appId: "1:203572716862:web:99e84f8737a1ecdb75b86f",
   measurementId: "G-DQ5YY0QX73"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
